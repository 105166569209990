<template>
  <div class="production-monitor" :class="{'table-expanded':isTableExpanded}">
    <div class="top-area">
      <div class="top-left">
        <el-input
          clearable
          placeholder="自编号"
          v-model="form.number"
          @keydown.enter.native="fetchCarList"
        >
          <el-button slot="append" @click="fetchCarList">查询</el-button>
        </el-input>
        <el-radio-group size="mini" v-model="listType" text-color class="left-switch">
          <el-radio-button label="组织"></el-radio-button>
          <el-radio-button label="状态"></el-radio-button>
        </el-radio-group>
        <div class="car-number-list scrollbar">
          <div v-if="listType === '组织'">
            <el-tree
              ref="tree"
              :data="treeData"
              show-checkbox
              node-key="id"
              default-expand-all
              :props="{children: 'list'}"
              @check-change="handleTreeCheckChange"
            >
              <span class="custom-tree-node" slot-scope="{ data }">
                <span v-if="data.carId" :title="data.number">
                  <img :src="jobStatusImageLightMap[data.jobStatus || 5]">
                  {{ data.number }}
                </span>
                <span v-else>{{ `${data.name}(${data.onlineCarNumber}/${data.totalCarNumber})` }}</span>
              </span>
            </el-tree>
          </div>
          <div v-if="listType === '状态'">
            <el-checkbox
              v-for="n in carNumberList"
              :key="n.carId"
              v-model="checkedNumberMap[n.carId]"
              @change="selectCar(n)"
            >
              <img :src="jobStatusImageLightMap[n.jobStatus || 5]">
              {{n.number}}
            </el-checkbox>
          </div>
        </div>
      </div>
      <div class="top-right">
        <div class="status-area">
          <el-checkbox-group v-model="form.jobStatusList" size="mini" @change="fetchCarList">
            <el-checkbox v-for="i in 6" :label="i" border="" :key="i">
              <img class="checked-bg-img" src="/img/tri.png">
              <img class="checked-img" src="/img/checked.png">
              <img :src="jobStatusImageLightMap[i]">
              {{statusInfos[i - 1]}}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <el-tabs v-model="activeTab">
          <el-tab-pane label="视频模式" name="video"></el-tab-pane>
          <el-tab-pane label="地图模式" name="map"></el-tab-pane>
        </el-tabs>
        <div class="inner-container" :class="{'show-video':showVideoTab}">
          <div class="video-panel" id="myVideoDiv">
            <!-- <div class="arrow-wrapper" :class="{disabled: videoPageIndex <= 0}">
              <img
                src="../../../assets/img/left-arrow.png"
                v-if="videos.length > 1"
                @click="prevPage"
              >
            </div> -->
            <div class="video-container">
              <div
                class="panel-title"
                v-if="checkedNumberList.length"
              >{{checkedNumberList[videoPageIndex].number}}&nbsp;&nbsp;<el-button size="mini" @click="showOnlinePatrol(checkedNumberList[videoPageIndex].number)" type="primary">增加线上巡查</el-button></div>
              <div class="panel-content">
                <div class="video-page">
                  <!-- <div
                    class="video-item"
                    v-for="(v, i) in myCurrentVideos"
                    :key="i"
                  >
                    <flv-player2 v-if="v.videoUrl" :video-url="v.videoUrl"></flv-player2>
                    <img v-else src="/img/videoDefault.png" alt="" style="display: block; width: 100%; height: calc(100% - 32px);">
                    <div class="video-title">
                      <div class="title-bg"></div>
                      <span>{{videoType[i]}}</span>
                    </div>
                  </div>
                  <div class="video-item video-item-placeholder"></div> -->
                  <el-tabs style="width:100%;" tab-position="left" v-model='activityTab' type="border-card" @tab-click="tabsClick">
                    <el-tab-pane class="tabPaneClass" :label="videoType[index]" :name="videoType[index]" v-for="(item, index) in videoType" :key="index">
                      <div style="width:100%;">
                        <flv-player2 v-if="myCurrentVideos.length > 0 && myCurrentVideos[0].videoUrl" :video-url="myCurrentVideos[0].videoUrl"></flv-player2>
                        <img v-else src="/img/videoDefault.png" alt="" style="display: block;width: 100%;height: 100%;">
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
            <!-- <div
              class="arrow-wrapper"
              :class="{disabled: videoPageIndex >= checkedNumberList.length - 1}"
            >
              <img
                src="../../../assets/img/right-arrow.png"
                v-if="videos.length > 1"
                @click="nextPage"
              >
            </div> -->
          </div>
          <div id="map"></div>
        </div>
      </div>
    </div>
    <div class="bottom-area scrollbar" ref="bottom">
      <div class="expand-table-icon">
        <div class="icon-wrapper">
          <!-- <i class="el-icon-arrow-up" @click="isTableExpanded=!isTableExpanded"></i> -->
        </div>
      </div>
      <div class="table-outer-wrapper">
        <el-table
          :data="tableData"
          stripe
          size="mini"
          :border="true"
          :height="tableHeight"
          @row-dblclick="approve"
        >
          <el-table-column
            header-align="center"
            align="center"
            v-for="h in headers"
            :key="h.id"
            :label="h.label"
            :prop="h.prop"
            :width="h.width"
            :min-width="h.minWidth"
            :show-overflow-tooltip="h.showTips"
            :formatter="h.formatter"
          ></el-table-column>
        </el-table>
        <el-pagination
          class="pager"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[10, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <div class="drawer-content scrollbar" :class="{visible}">
      <el-button
        v-if="visible == true"
        class="close-button"
        size="mini"
        icon="el-icon-close"
        circle
        @click="visible = !visible"
      ></el-button>
      <el-row :gutter="20">
        <el-col :span="3" v-for="(image, i) in imageList" :key="i">
          <div class="upload-wrapper">
            <image-uploader disabled :limit="1" :images="image.list" no-title></image-uploader>
            <div class="title">{{image.title}}</div>
          </div>
        </el-col>
      </el-row>
      <div class="flex-level">
        <el-input v-model="remark" v-if="currentCarPermissionStatus !== 2" :disabled="currentCarPermissionStatus !== 1"></el-input>
        <el-button type="success" v-if="currentCarPermissionStatus == 1" @click="verifyMethod">审核通过</el-button>
        <el-button type="danger" v-if="currentCarPermissionStatus == 1" @click="abortMethod">审核不通过</el-button>
      </div>
    </div>

    <!-- 巡查任务下发弹窗 -->
    <el-dialog
      title="巡查任务下发"
      :visible.sync="dialogVisible"
      width="300px">
      <el-select filterable v-model="currentUserId" placeholder="请选择人员" size="small" style="width: 100%;">
        <el-option
          v-for="item in userList"
          :key="item.id"
          :label="item.nickName"
          :value="item.id">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="patrolDispatch" size="small">确 定</el-button>
      </span>
    </el-dialog>

    <!--新增线上巡查-->
    <el-dialog id="myDlgDiv"
               title="线上巡查任务"
               :visible.sync="onlineDialogVisible"
               width="800px"
               :close-on-press-escape="false"
               :close-on-click-modal="false">
      <div style="font-size:14px;margin-top: 10px; margin-bottom:10px;">请先选择车辆的调运任务</div>
      <el-select v-model="patrolDispatchTaskId" @change="initPatrol(patrolDispatchTaskId)" filterable size="mini" style="width: 80%">
        <el-option
          v-for="item in currentDispatchTaskList"
          :key="item.dispatchTaskId"
          :label="item.labelName"
          :value="item.dispatchTaskId">
        </el-option>
      </el-select>
      <el-form>
        <el-row :gutter="20">
          <el-col :span="6">
            <div>准泵令状态: &nbsp;&nbsp;{{permission.permissionStatus}}</div>
          </el-col>
          <el-col :span="16">
            <div>创建时间: &nbsp;&nbsp;{{permission.permissionTime}}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6" v-for="(image, i) in permission.permissionImageList" :key="i">
            <div class="upload-wrapper">
              <image-uploader disabled :limit="1" :images="image.list" no-title></image-uploader>
              <div class="title">{{image.title}}</div>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div style="font-size:14px;margin-top: 10px; margin-bottom:10px;" v-if="patrolCreateTime == null">您选择了调运任务，但还没有创建过巡查任务</div>
      <div style="font-size:14px;margin-top: 10px; margin-bottom:10px;" v-if="patrolCreateTime != null">您已经为车辆创建过巡查任务，创建时间为 {{patrolCreateTime}}</div>
      <el-form>
        <div v-for="item in this.onlinePatrolContents" :key="item.dictId" style="display:flex;justify-content: space-between;align-items: center;border: 1px solid black;border-bottom-style: 0.5px">
          <div style="text-align: center">{{item.dictName}}</div>
          <el-select v-model="item.patrolStatus" size="mini" style="width: 20%">
            <el-option
              v-for="statusItem in patrolStatusList"
              :key="statusItem.value"
              :label="statusItem.name"
              :value="statusItem.value">
            </el-option>
          </el-select>
        </div>
        <el-row>
          <div style="font-size:14px;margin-top: 10px; margin-bottom:5px;">备注</div>
        </el-row>
        <el-row>
          <el-input class="remark" v-model="patrolRemarks"></el-input>
        </el-row>
        <el-row>
          <div v-if="!captured" style="color: red">您还没有获取实时图像，请先点击获取实时图像才能保存巡查任务</div>
          <el-button size="small" @click="capture">获取实时图像</el-button>
        </el-row>
        <el-row>
          <image-uploader
            @add="addImage($event)"
            @remove="removeImage($event)"
            :images="patrolImageList || []"
            no-title
          ></image-uploader>
        </el-row>
        <el-form-item >
          <el-button @click="onlineDialogVisible = false" size="small">取 消</el-button>
          <el-button type="primary" @click="saveOnlinePatrol" size="small">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapCenter, videoType, restfulUrl, webSocketUrl, staticUrl } from '@/config'
import FlvPlayer2 from '@/components/flv-player2.vue'
import axios from 'axios'
import { jobStatusImageLightMap } from '@/utils/constants'
// import { debounce } from 'throttle-debounce'
import ImageUploader from '@/components/image-uploader'
import _ from 'lodash'

export default {
  name: 'production-monitor',

  components: { FlvPlayer2, ImageUploader },

  data () {
    return {
      captured: false,
      activityTab: '左前支撑腿',
      onlineDialogVisible: false,
      onlinePatrolContents: [],
      permission: {
        permissionImageList: [],
        permissionStatus: '',
        permissionTime: ''
      },
      permissionStatusList: [{
        name: '待处理',
        value: 1
      }, {
        name: '已通过',
        value: 2
      }, {
        name: '拒绝',
        value: 3
      }, {
        name: '二次移泵',
        value: 3
      }],
      patrolStatusList: [{
        name: '通过',
        value: 1
      }, {
        name: '不通过',
        value: 2
      }, {
        name: '不适用',
        value: 3
      }],
      patrolDispatchTaskId: 0,
      patrolDispatchTaskLabel: '',
      currentDispatchTaskList: [],
      patrolCreateTime: null,
      patrolRemarks: '',
      patrolImageList: [],
      dialogVisible: false,
      userList: [], // 巡查下发可选人员列表
      currentUserId: '', // 巡查下发当前选中的人员
      jobStatusImageLightMap,
      listType: '组织',
      activeTab: 'map',
      isTableExpanded: true,
      videoType,
      isMounted: false,
      form: {
        number: null,
        jobStatusList: [1, 2, 3, 4, 5, 6]
      },
      treeData: [],
      statusInfos: [],
      carNumberList: [],
      checkedNumberList: [],
      checkedNumberMap: {},
      tableHeight: null,
      tableData: [],
      headers: [
        { id: -1, label: '序号', prop: 'index', width: 50 },
        { id: 1, label: '自编号', prop: 'number', width: 80 },
        { id: 2, label: '车牌号码', prop: 'licensePlate', width: 100 },
        { id: 3, label: '车长', prop: 'carNickName', width: 80 },
        { id: 4, label: '车长电话', prop: 'phone', width: 120 },
        {
          id: 5,
          label: '状态',
          prop: 'status',
          width: 80,
          formatter: (row, column, cellValue) => {
            if (cellValue === 1) {
              return '待处理'
            } else if (cellValue === 2) {
              return '已通过'
            } else if (cellValue === 3) {
              return '拒绝'
            } else if (cellValue === 4) {
              return '二次移泵'
            }
          }
        },
        { id: 6, label: '位置', prop: 'location', minWidth: 200, showTips: true },
        { id: 7, label: '提交时间', prop: 'createTime', width: 120 },
        { id: 8, label: '审批人', prop: 'approveUserName', width: 100 },
        { id: 9, label: '描述', prop: 'remarks', minWidth: 200, showTips: true }
      ],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      videos: [],
      videoPageIndex: 0,
      myCurrentVideos: [],
      markList: [],
      visible: false,
      imageList: [],
      currentCarPermissionStatus: 1,
      currentCarLocation: '',
      remark: '',
      approveId: ''
    }
  },

  computed: {
    expanded: {
      get () { return this.$store.state.common.expanded }
    },

    showVideoTab () {
      return this.activeTab === 'video'
    },

    currentVideos () {
      if (this.videos.length === 0) {
        return []
      } else {
        return this.videos[this.videoPageIndex]
      }
    }

  },

  watch: {
    currentVideos: {
      deep: true,
      handler () {
      }
    },

    captured: {
      deep: true,
      handler () {
      }
    },

    isTableExpanded (v) {
      setTimeout(() => {
        this.handleWindowResize()
      }, 300)
    },

    showVideoTab (v) {
      if (v) {
        this.loadingVideos()
        this.connectVideos(2)
      }
    },

    listType (v) {
      this.resetCheckedStates()
    },

    visible (v) {
      if (!v) {
        this.imageList = []
        this.remark = ''
      }
    }
  },

  mounted () {
    this.map = new window.AMap.Map('map', {
      center: mapCenter,
      zoom: 5
    })

    const that = this
    // 同时引入工具条插件，比例尺插件和鹰眼插件
    window.AMap.plugin([
      'AMap.ToolBar',
      'AMap.Scale',
      'AMap.OverView',
      'AMap.MapType',
      'AMap.Geolocation'
    ], function () {
      // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
      that.map.addControl(new window.AMap.ToolBar())

      // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
      that.map.addControl(new window.AMap.Scale())

      // 在图面添加鹰眼控件，在地图右下角显示地图的缩略图
      that.map.addControl(new window.AMap.OverView({ isOpen: true }))

      // 在图面添加类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
      that.map.addControl(new window.AMap.MapType())

      // 在图面添加定位控件，用来获取和展示用户主机所在的经纬度位置
      // that.map.addControl(new window.AMap.Geolocation())
    })

    this.isMounted = true
    this.$nextTick(() => {
      this.handleWindowResize()
      let self = this
      this.$el.onclick = e => {
        if (e.target.className.indexOf('patrol-dispatch-button') > -1) {
          // alert('巡查任务下发')
          self.currentUerId = ''
          self.dialogVisible = true
        }
      }
    })
  },

  async created () {
    this.$http({
      url: this.$http.adornUrl('/production/carJobStatus/statistics'),
      method: 'post'
    }).then((data) => {
      this.statusInfos = data.datas.map(d => d.dictName + ':' + (d.carNumber || 0))
    })
    this.fetchCarList()
    this.fetchTableData()
    window.addEventListener('resize', this.handleWindowResize)
    this.deboucedProcess()
    this.$http({
      url: this.$http.adornUrl('/user/listpatrol'),
      method: 'post',
      data: {
        userType: 1
      }
    }).then(data => {
      this.userList = data.datas || []
    })
    console.log(this.myCurrentVideos)
  },

  methods: {
    tabsClick (target) {
      console.log(target.index)
      this.connectVideos(target.index)
    },
    connectVideos (index) {
      this.myCurrentVideos = _.map(new Array(8), item => {
        return { videoUrl: '' }
      })
      console.log(this.currentVideos)
      let promiseArr = []
      let list = []
      promiseArr.push(new Promise((resolve, reject) => {
        axios.get(this.currentVideos[index].switchUrl).then((data) => {
          if ([1, -85].includes(data.data.code)) {
            // list.push({ videoUrl: item.videoUrl })
            resolve()
          } else {
            reject(new Error('视频预连接不成功'))
          }
        }).catch(err => {
          reject(err)
        })
      }))
      list.push({ videoUrl: this.currentVideos[index].videoUrl })

      if (this.videoLoading && this.videoLoading.close) {
        this.videoLoading.close()
      }
      this.videoLoading = this.$loading({
        target: '#myVideoDiv',
        lock: false,
        text: '正在请求视频，请您稍后',
        spinner: 'el-icon-loading',
        customClass: 'video-loading-div'
        // background: 'rgba(0, 0, 0, 0.7)'
      })
      Promise.all(promiseArr).then(() => {
        this.myCurrentVideos = list
        console.log(this.myCurrentVideos)
      }).catch(err => {
        console.log(err)
        this.$message({
          type: 'warning',
          // customClass: 'el-message__home',
          message: '当前设备实时监控视频离线'
        })
      }).finally(() => {
        if (this.videoLoading && this.videoLoading.close) {
          this.videoLoading.close()
        }
      })
    },
    deboucedProcess () {
      // debounce(1000, () => {
      this.createMapMarks()
      if (this.checkedNumberList.length) {
        this.videoPageIndex = 0
        this.loadingVideos()
      }
      // })
    },
    // 线上巡查窗口打开
    showOnlinePatrol (number) {
      this.$http({
        url: this.$http.adornUrl('/dispatch/taskPatrol/currenttask'),
        method: 'post',
        data: number
      }).then(data => {
        if (data.code === 200) {
          let list = data.datas
          if (list == null || list === undefined || list.length === 0) {
            this.$message.warning('当前车辆今天没有任何调运任务，不能添加线上巡查记录')
          } else {
            this.onlineDialogVisible = true
            this.currentDispatchTaskList = list
            this.patrolDispatchTaskId = list[0].dispatchTaskId
            console.log(this.patrolDispatchTaskId)
            this.initPatrol(this.patrolDispatchTaskId)
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    initPatrol (patrolDispatchTaskId) {
      this.$http({
        url: this.$http.adornUrl('/dispatch/taskPatrol/getByDispatchTaskId'),
        method: 'post',
        data: patrolDispatchTaskId
      }).then(data => {
        if (data.code === 200) {
          let patrol = data.datas
          this.patrolCreateTime = patrol.createTime
          this.onlinePatrolContents = patrol.contentList
          this.patrolRemarks = patrol.remarks
          this.patrolDispatchTaskId = patrol.dispatchTaskId || []
          console.log(patrol.images)
          if (patrol.images != null && patrol.images !== '') {
            this.patrolImageList = patrol.images.split(',').map(j => ({ url: staticUrl + j }))
            console.log(this.patrolImageList)
          } else {
            this.patrolImageList = []
          }
          if (this.patrolImageList.length > 0) {
            this.captured = true
          }
          console.log(this.patrolImageList)
          console.log(this.patrolImageList.length)

          this.$http({
            url: this.$http.adornUrl('/production/carPermission/getByDispatchTaskId'),
            method: 'post',
            data: this.patrolDispatchTaskId
          }).then(data => {
            console.log(data)
            let list = []
            list.push({ title: '左前支撑腿照片', list: [{ url: data.datas.imageBracketBeforeLeft ? staticUrl + data.datas.imageBracketBeforeLeft : '' }] })
            list.push({ title: '左后支撑腿照片', list: [{ url: data.datas.imageBracketAfterLeft ? staticUrl + data.datas.imageBracketAfterLeft : '' }] })
            list.push({ title: '右前支撑腿照片', list: [{ url: data.datas.imageBracketBeforeRight ? staticUrl + data.datas.imageBracketBeforeRight : '' }] })
            list.push({ title: '右后支撑腿照片', list: [{ url: data.datas.imageBracketAfterRight ? staticUrl + data.datas.imageBracketAfterRight : '' }] })
            list.push({ title: '整车照片', list: [{ url: data.datas.imageWholeCar ? staticUrl + data.datas.imageWholeCar : '' }] })
            list.push({ title: '就位确认单照片', list: [{ url: data.datas.imageConfirm ? staticUrl + data.datas.imageConfirm : '' }] })
            list.push({ title: '单边支撑照片', list: [{ url: data.datas.imageSingleSide ? staticUrl + data.datas.imageSingleSide : '' }] })
            list.push({ title: '危险源施工方案照片', list: [{ url: data.datas.imageHazardSource ? staticUrl + data.datas.imageHazardSource : '' }] })
            this.permission.permissionImageList = list
            let resultArray = this.permissionStatusList.filter(e => e.value === data.datas.status)
            this.permission.permissionStatus = resultArray[0].name || ''
            // this.permission.permissionStatus = data.datas.status
            this.permission.permissionTime = data.datas.createTime
          }).catch(err => {
            console.log(err)
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    addImage (url) {
      this.patrolImageList.push(url)
      if (this.patrolImageList.length > 0) {
        this.captured = true
      }
      console.log(this.patrolImageList)
    },

    removeImage (url) {
      this.patrolImageList.splice(this.patrolImageList.indexOf(url), 1)
      if (this.patrolImageList.length === 0) {
        this.captured = false
      }
    },
    // 捕获实时图片
    capture () {
      let patrolLoading = this.$loading({
        // target: '#myDlgDiv',
        lock: false,
        text: '正在请求实时图片，大约需要30秒，请您稍后~',
        spinner: 'el-icon-loading',
        customClass: 'video-loading-div',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      setTimeout(function () { // 设定定时器，超时60S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
        if (patrolLoading) {
          patrolLoading.close()
          this.$message.error('捕获实时图片失败，请重试')
        } // 关闭遮罩层
      }, 60000)

      this.$http({
        url: this.$http.adornUrl('/dispatch/taskPatrol/capture'),
        method: 'post',
        data: this.patrolDispatchTaskId
      }).then(data => {
        console.log(data.datas)
        if (data.code === 200) {
          if (data.datas && data.datas.length > 0) {
            for (let i = 0; i < data.datas.length; i++) {
              if (data.datas[i] && data.datas[i] != null) {
                let obj = {}
                obj.url = staticUrl + data.datas[i]
                this.patrolImageList.push(obj)
              }
            }
            console.log(this.patrolImageList)
          }
          this.$message.success('获取实时图片成功')
          this.captured = true
          patrolLoading.close()
        } else {
          this.$message.error('获取实时图片失败')
          this.captured = true
          patrolLoading.close()
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 增加巡查任务
    saveOnlinePatrol () {
      if (!this.captured) {
        this.$message.error('您还没有获取实时图像，请先点击 获取实时图片像 按钮')
        return
      }
      let patrolImages = this.patrolImageList.map(e => e.url.substring(e.url.indexOf('files') + 5)).join(',')
      console.log(patrolImages)
      console.log(this.patrolImageList)
      let jsonData = {}
      jsonData.dispatchTaskId = this.patrolDispatchTaskId
      jsonData.remarks = this.patrolRemarks
      jsonData.images = patrolImages
      jsonData.contentList = this.onlinePatrolContents
      this.$http({
        url: this.$http.adornUrl('/dispatch/taskPatrol/savepatrol'),
        method: 'post',
        data: JSON.stringify(jsonData)
      }).then(data => {
        if (data.code === 200) {
          this.patrolDispatchTaskId = 0
          this.patrolRemarks = ''
          this.onlinePatrolContents = []
          this.patrolImageList = []
          this.currentDispatchTaskList = []
          this.onlineDialogVisible = false
          this.$message.success('添加线上巡查记录成功')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    /** 巡查任务下发 */
    patrolDispatch () {
      if (!this.currentUserId) {
        this.$message.warning('请选择人员')
        return
      }
      this.$http({
        url: this.$http.adornUrl('/dispatch/taskPatrol/sendpatrol'),
        method: 'post',
        data: {
          dispatchTaskId: this.currentDispatchTaskId,
          patrolUser: this.currentUserId
        }
      }).then(data => {
        if (data.code === 200) {
          this.$message.success('下发成功')
          this.dialogVisible = false
        }
        this.currentUserId = ''
      }).catch(err => {
        console.log(err)
      })
    },
    handleTreeCheckChange (data, checked) {
      if (Object.prototype.hasOwnProperty.call(data, 'carId')) {
        // 车辆
        const target = this.carNumberList.find(c => c.carId === data.carId)
        if (target) {
          this.selectCar(target)
        }
      }
    },

    fetchCarList () {
      this.fetchOrgList()
      this.fetchCarNumberList()
    },

    nextPage () {
      if (this.videoPageIndex < this.checkedNumberList.length - 1) {
        this.videoPageIndex++
      }
    },

    prevPage () {
      if (this.videoPageIndex > 0) {
        this.videoPageIndex--
      }
    },

    selectCar (car) {
      if (this.checkedNumberList.find(c => c.carId === car.carId)) {
        this.checkedNumberMap[car.carId] = false
        this.checkedNumberList.splice(this.checkedNumberList.indexOf(car), 1)
      } else {
        this.checkedNumberMap[car.carId] = true
        this.checkedNumberList.push(car)
      }
      this.deboucedProcess()
    },

    async loadingVideos () {
      if (!this.showVideoTab) return
      const videos = []
      this.checkedNumberList.forEach(p => {
        let carId = p.carDeviceId
        const list = []
        for (let i = 1; i < 9; i++) {
          const param = {
            tdh: i,
            type: 1,
            tid: carId
          }
          //   if (i === 4) {
          //     list.push({
          //       switchUrl: restfulUrl + `/api.json?func=1003&carid=${carId}&tdh=${i}&ml=0`,
          //       videoUrl: webSocketUrl + `/websocket?token=&params=${encodeURIComponent(JSON.stringify(param))}`
          //     })
          //   } else {
          //     list.push({
          //       switchUrl: restfulUrl + `/api.json?func=1003&carid=${carId}&tdh=${i}`,
          //       videoUrl: webSocketUrl + `/websocket?token=&params=${encodeURIComponent(JSON.stringify(param))}`
          //     })
          //   }

          list.push({
            switchUrl: restfulUrl + `/api.json?func=1003&carid=${carId}&tdh=${i}&ml=0`,
            videoUrl: webSocketUrl + `/websocket?token=&params=${encodeURIComponent(JSON.stringify(param))}`
          })
        }
        videos.push(list)
      })
      if (!this.checkedNumberList.length) {
        alert('请选择车辆后查看视频')
        return
        // const list = []
        // for (let i = 1; i < 9; i++) {
        //   list.push({
        //     switchUrl: '',
        //     videoUrl: ''
        //   })
        // }
        // videos.push(list)
      }
      this.videos = videos
    },

    createMapMarks () {
      if (!this.isMounted) return

      // 先清除标记，再添加
      for (const m of this.markList) {
        m.off('click', this.handleMarkerClick)
      }
      this.map.remove(this.markList)
      if (this.checkedNumberList.length) {
        const AMap = window.AMap
        this.markList = []
        const targetCar = this.checkedNumberList[this.checkedNumberList.length - 1]
        for (const item of this.checkedNumberList) {
          if (!item.longitude || !item.latitude) continue
          const content = `<img src="${jobStatusImageLightMap[item.jobStatus || 5]}" style="height: 24px;">`

          const marker = new AMap.Marker({
            position: new AMap.LngLat(item.longitude, item.latitude),
            content,
            anchor: 'bottom',
            title: item.number,
            item
          })
          marker.on('click', this.handleMarkerClick)
          this.markList.push(marker)
          this.map.add(marker)
        }
        setTimeout(() => {
          if (targetCar.longitude && targetCar.latitude) {
            const coordinates = [targetCar.longitude, targetCar.latitude]
            this.map.setZoomAndCenter(18, coordinates)
            this.setWindowContent(targetCar)
          }
        }, 500)
      } else {
        this.clearTimer()
        this.infoWindow && this.infoWindow.close()
      }
    },

    clearTimer () {
      this.timer && clearInterval(this.timer)
    },

    setWindowContent (targetCar) {
      this.clearTimer()
      this.timer = setInterval(() => {
        this.getCarMapInfo(targetCar)
      }, 60 * 1000)
      this.getCarMapInfo(targetCar, true)
    },

    getCarMapInfo (targetCar, open = false) {
      // console.log('dispatchTaskId == ', targetCar.dispatchTaskId)
      if (this.showVideoTab) return
      axios.get(restfulUrl + `/api.json?func=1002&carid=${targetCar.carDeviceId}`).then(async (res) => {
        const data = res.data.data
        // console.log(JSON.parse(JSON.stringify(data)))
        let runningStatus = '离线'
        if (data.online === 1) {
          runningStatus = '车辆在线'
        }
        let targetCar = this.carNumberList.find(c => c.carDeviceId === data.carno)
        const AMap = window.AMap
        const coordinates = [targetCar.longitude, targetCar.latitude]
        await this.getLocation(data.lng, data.lat)
        let direction = this.getDirection(data.direction)
        let content = `
            <div class="window-info-row">
              <div><label>自编号：</label>${targetCar.number}</div>
              <div><label>基地：</label>白莲泾</div>
            </div>
            <div class="window-info-row">
              <div><label>车牌：</label>${targetCar.licensePlate ? targetCar.licensePlate : ''}</div>
              <label>运行状态：</label>${runningStatus}
            </div>
            <div class="window-info-row">
              <div><label>经度：</label>${data.lng}</div>
              <div><label>纬度：</label>${data.lat}</div>
            </div>
            <div class="window-info-row">
              <label>地址：</label>${this.currentCarLocation}
            </div>
            <div class="window-info-row">
              <div><label>速度：</label>${data.speed} km/h</div>
              <div><label>方向：</label>${direction}</div>
            </div>
            <div class="window-info-row">
              <label>状态：</label>${data.status}
            </div>
            `
        if (targetCar.jobStatus === 2 && targetCar.dispatchTaskId) {
          content = content + `
            <div class="btns" style="margin-top: 16px; text-align: center;">
              <button class="patrol-dispatch-button el-button el-button--primary el-button--small">巡查任务下发</button>
            </div>
          `
        }
        this.currentDispatchTaskId = targetCar.dispatchTaskId
        if (open) {
          if (!this.infoWindow) {
            this.infoWindow = new AMap.InfoWindow({
              content,
              offset: new AMap.Pixel(0, -20)
            })
            this.infoWindow.on('close', () => {
              this.clearTimer()
            })
          }
          this.infoWindow.open(this.map, coordinates)
        }
        if (this.infoWindow && this.infoWindow.getIsOpen()) {
          this.infoWindow.setContent(content)
        }
      })
    },

    async getLocation (lng, lat) {
      const data = await this.$http({
        url: this.$http.adornUrl('/api/common/getGeocodeBatch'),
        method: 'post',
        data: [{ longitude: lng, latitude: lat }]
      })
      this.currentCarLocation = data.datas[0]
    },

    getDirection (direction) {
      if (direction >= 0 && direction <= 22.5) {
        return '北'
      } else if (direction > 22.5 && direction <= 67.5) {
        return '东北'
      } else if (direction > 67.5 && direction <= 112.5) {
        return '东'
      } else if (direction > 112.5 && direction <= 157.5) {
        return '东南'
      } else if (direction > 157.5 && direction <= 202.5) {
        return '南'
      } else if (direction > 202.5 && direction <= 247.5) {
        return '西南'
      } else if (direction > 247.5 && direction <= 292.5) {
        return '西'
      } else {
        return '西北'
      }
    },

    handleMarkerClick (e) {
      this.setWindowContent(e.target.w.item)
    },

    handleWindowResize () {
      // 最大化无 $refs
      if (this.$refs.bottom) {
        // this.tableHeight = this.isTableExpanded ? this.$refs.bottom.clientHeight - 95 : null
        this.tableHeight = this.isTableExpanded ? this.$refs.bottom.clientHeight - 85 : null
      }
    },

    async fetchOrgList () {
      const data = await this.$http({
        url: this.$http.adornUrl('/production/station/list'),
        method: 'post',
        data: this.form
      })
      function process (list) {
        for (const item of list) {
          if (!Object.prototype.hasOwnProperty.call(item, 'id')) {
            item.id = item.carId
          } else {
            item.id = 'not_car_' + item.id
          }
          if (item.list) {
            process(item.list)
          }
        }
      }
      process(data.datas)
      this.treeData = data.datas
    },

    async fetchCarNumberList () {
      const data = await this.$http({
        url: this.$http.adornUrl('/production/car/list'),
        method: 'post',
        data: this.form
      })
      this.carNumberList = data.datas
      this.resetCheckedStates()
    },

    resetCheckedStates () {
      const map = {}
      for (const c of this.carNumberList) {
        map[c.carId] = false
      }
      this.checkedNumberList = []
      this.checkedNumberMap = map
    },

    async fetchTableData () {
      const tData = await this.$http({
        url: this.$http.adornUrl('/production/carPermission/list'),
        method: 'post',
        data: this.$http.adornData({
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
      })
      if (tData.code === 200) {
        this.tableData = tData.datas.list.map((d, i) => ({
          ...d,
          index: i + 1
        }))
        this.total = tData.datas.total
      }
    },

    handleSizeChange (v) {
      this.pageNum = 1
      this.pageSize = v
      this.fetchTableData()
    },

    handleCurrentChange (v) {
      this.pageNum = v
      this.fetchTableData()
    },

    async approve (car) {
      const target = this.carNumberList.find(c => c.carId === car.carId)
      if (target) {
        this.visible = true
        if (this.listType === '组织') {
          this.$refs.tree.setChecked(target.carId, true)
        }
        if (this.checkedNumberList.includes(target) || this.listType === '组织') {
          if (target.longitude && target.latitude) {
            const coordinates = [target.longitude, target.latitude]
            this.map.setZoomAndCenter(18, coordinates)
            this.setWindowContent(target)
          }
        } else {
          this.selectCar(target)
        }
        this.approveId = car.id
        const data = await this.$http({
          url: this.$http.adornUrl('/production/carPermission/getById'),
          method: 'post',
          data: car.id
        })
        if (data && data.code === 200) {
          this.dataForm = data.datas
          const list = []
          list.push({ title: '左前支撑腿照片', list: [{ url: data.datas.imageBracketBeforeLeft ? staticUrl + data.datas.imageBracketBeforeLeft : '' }] })
          list.push({ title: '左后支撑腿照片', list: [{ url: data.datas.imageBracketAfterLeft ? staticUrl + data.datas.imageBracketAfterLeft : '' }] })
          list.push({ title: '右前支撑腿照片', list: [{ url: data.datas.imageBracketBeforeRight ? staticUrl + data.datas.imageBracketBeforeRight : '' }] })
          list.push({ title: '右后支撑腿照片', list: [{ url: data.datas.imageBracketAfterRight ? staticUrl + data.datas.imageBracketAfterRight : '' }] })
          list.push({ title: '整车照片', list: [{ url: data.datas.imageWholeCar ? staticUrl + data.datas.imageWholeCar : '' }] })
          list.push({ title: '就位确认单照片', list: [{ url: data.datas.imageConfirm ? staticUrl + data.datas.imageConfirm : '' }] })
          list.push({ title: '单边支撑照片', list: [{ url: data.datas.imageSingleSide ? staticUrl + data.datas.imageSingleSide : '' }] })
          list.push({ title: '危险源施工方案照片', list: [{ url: data.datas.imageHazardSource ? staticUrl + data.datas.imageHazardSource : '' }] })
          this.imageList = list
          this.currentCarPermissionStatus = data.datas.status
        }
      }
    },

    async abortMethod () {
      const data = await this.$http({
        url: this.$http.adornUrl('/production/carPermission/review'),
        method: 'post',
        data: {
          id: this.approveId,
          status: 3,
          remarks: this.remark
        }
      })
      if (data.code === 200) {
        this.$opts.success()
        this.visible = false
        this.fetchTableData()
      }
    },

    async verifyMethod () {
      const data = await this.$http({
        url: this.$http.adornUrl('/production/carPermission/review'),
        method: 'post',
        data: {
          id: this.approveId,
          status: 2,
          remarks: this.remark
        }
      })
      if (data.code === 200) {
        this.$opts.success()
        this.visible = false
        this.fetchTableData()
      }
    }
  },

  beforeDestroy () {
    this.clearTimer()
  },
  handleCloseDlg() {
    this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
  }
}
</script>

<style lang="scss">
.production-monitor {
  padding: 20px 20px 0 20px;
  .top-area {
    height: calc(100vh - 106px);
    display: flex;
    transition: all 0.2s;
    .top-left {
      width: 200px;
      display: flex;
      flex-direction: column;
      .el-radio-group {
        margin: 20px auto 0;
        .el-radio-button__orig-radio:checked + .el-radio-button__inner {
          background-color: #c20c0c;
          border-color: #c20c0c;
          color: #fff;
        }
        .el-radio-button__inner:hover {
          color: #c20c0c;
        }
      }
      .el-checkbox__input.is-checked .el-checkbox__inner,
      .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: transparent;
        border-color: #c62f2f;
      }
      .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
        background-color: #c20c0c;
      }
      .el-checkbox__inner:hover,
      .el-checkbox__inner::after {
        border-color: #c20c0c;
      }
      .left-switch {
        width: 100%;
        margin-top: 13px;
        .el-radio-button {
          width: 50%;
          .el-radio-button__inner {
            width: 100%;
          }
        }
      }
      .car-number-list {
        height: 100%;
        background: #fff;
        border-radius: 4px;
        margin-top: 10px;
        .el-checkbox {
          display: block;
          margin: 10px;
        }
        img {
          height: 14px;
          position: relative;
          top: 3px;
          left: -3px;
        }
        .el-tree {
          margin: 5px;
          .el-checkbox {
            margin: 10px 5px;
          }
          .el-tree-node__content > .el-tree-node__expand-icon {
            padding: 2px;
          }
        }
      }
    }
    .top-right {
      margin-left: 15px;
      width: calc(100% - 235px);
      position: relative;
      .status-area {
        position: absolute;
        top: 5px;
        left: 200px;
        right: 0;
        z-index: 1;
        .el-checkbox-group {
          display: flex;
          justify-content: space-between;
          .el-checkbox {
            flex: 1 0 85px;
            text-align: center;
            margin: 0 5px;
            .el-checkbox__label {
              padding-left: 0;
            }
            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
          }
          .el-checkbox.is-bordered.is-checked {
            border-color: #c20c0c;
            .checked-bg-img,
            .checked-img {
              display: initial;
            }
          }
          .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #1a1a1a;
          }
          .el-checkbox__inner {
            display: none;
          }
        }
        .el-checkbox {
          position: relative;
        }
        .checked-bg-img {
          display: none;
          position: absolute;
          top: -1px;
          right: -1px;
          width: 20px;
          height: 16px;
          z-index: 1;
        }
        .checked-img {
          display: none;
          position: absolute;
          top: 1px;
          right: 1px;
          width: 8px;
          height: 6px;
          z-index: 2;
        }
        img {
          height: 14px;
          position: relative;
          top: 3px;
        }
        @media screen and (max-width: 1440px) {
          .el-checkbox {
            margin-right: 20px;
          }
        }
      }
      .el-tabs__header {
        .el-tabs__item {
          height: 36px;
          &:hover,
          &.is-active {
            color: #c20c0c;
          }
        }
        .el-tabs__active-bar {
          background-color: #c20c0c;
        }
      }
      .inner-container {
        display: flex;
        height: calc(100% - 50px);
        &.show-video {
          #map {
            width: 0;
          }
          .video-panel {
            width: 100%;
          }
        }
      }

      #map {
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 100%;
        height: 100%;
      }
      .video-panel {
        width: 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        background: #fff;
        .no-data {
          font-size: 20px;
          color: #666;
          text-align: center;
          width: 100%;
          white-space: nowrap;
        }
        .arrow-wrapper {
          width: 15px;
          cursor: pointer;
          img {
            width: 100%;
          }
          &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
        .video-container {
          width: calc(100% - 30px);
          height: 100%;
          border-radius: 4px;
          padding: 0;
          position: relative;
          .panel-title {
            text-align: center;
            font-size: 16px;
            line-height: 20px;
            color: #1a1a1a;
            padding-top: 10px;
          }
          .panel-content {
            width: 100%;
            height: 100%;
            padding: 10px 0 0;
            overflow: hidden;
            display: flex;
            align-items: top;
            .video-page {
              padding: 0 0 5px 0;
              justify-content: center;
              margin-left: 15%;
            //   align-content: center;
              width: 66%;
            //   height: 100%;
              .tabPaneClass {
                  display: flex;
                  justify-content: center;
              }
            //   .video-item {
            //     margin: 0;
            //     // width: calc((100% - 15px) / 4);
            //     // height: calc((100% - 5px) / 2);
            //     width: calc(100% / 4);
            //     height: calc(100% / 2);
            //     box-sizing: border-box;
            //     border: 1px solid #000;
            //     &.video-item-placeholder {
            //       display: none;
            //     }
            //     .title-bg {
            //       border-radius: 0;
            //     }
            //   }
            }
          }
        //   @media screen and (max-width: 1440px) {
        //     .panel-content {
        //       .video-page {
        //         .video-item {
        //           // width: calc((100% - 10px) / 3);
        //           // height: calc((100% - 10px) / 3);
        //           width: calc(100% / 3);
        //           height: calc(100% / 3);
        //           &.video-item-placeholder {
        //             display: block;
        //           }
        //         }
        //       }
        //     }
        //   }
        }
      }
    }
  }
  .bottom-area {
    height: 79px;
    transition: all 0.2s;
    overflow: hidden;
    margin-top: 5px;
    .expand-table-icon {
      display: flex;
      justify-content: center;
      .icon-wrapper {
        padding: 10px;
        font-size: 20px;
        cursor: pointer;
        color: #999;
        &:hover {
          color: #666;
        }
      }
    }
    .table-outer-wrapper {
      border: 1px solid #ccc;
      border-top: 2px solid #c62f2f;
      border-radius: 2px 4px 4px 4px;
    }
    .el-table__body {
      user-select: none;
    }
    .pager {
      margin: 3px 0;
    }
  }
  &.table-expanded {
    .bottom-area {
      height: 35vh;
    }
    .top-area {
      height: calc(65vh - 20px);
    }
    @media screen and (max-width: 1440px) {
      .top-area {
        height: calc(80vh - 20px);
      }
    }
    .el-icon-arrow-up {
      transform: rotateZ(180deg);
    }
  }
  .el-icon-arrow-up {
    transition: all 0.2s;
  }
  .drawer-content {
    background: #fff;
    position: fixed;
    z-index: 999;
    bottom: -35vh;
    left: 0;
    right: 0;
    max-height: 35vh;
    padding: 10px 40px 10px 10px;
    transition: all 0.2s;
    &.visible {
      bottom: 0;
    }
    .close-button {
      position: fixed;
      z-index: 1000;
      right: 5px;
      bottom: calc(35vh - 35px);
    }
    // .el-row {
      // height: 100%;
    // }
    .el-input {
      margin-right: 20px;
    }
    .flex-level {
      width: 100%;
    }
  }
}
.flex-level {
  width: 100%;
}
.video-loading-div {
  .el-loading-text {
    color: #333;
  }
}
.el-form-item{
  text-align: center;
}
</style>

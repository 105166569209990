<template>
  <div class="flv-player-wrapper"></div>
</template>

<script>
import DPlayer from 'dplayer'
// import axios from 'axios'

export default {
  name: 'flv-player2',

  data () {
    return {

    }
  },

  props: {
    videoUrl: String
  },

  watch: {

  },

  methods: {
    init () {
      if (this.player) {
        this.player.destroy()
      }
      /* eslint-disable no-new */
      this.player = new DPlayer({
        container: this.$el,
        screenshot: true,
        live: false,
        mutex: false,
        video: {
          pic: '/img/videoDefault.png',
          url: this.videoUrl,
          type: 'flv'
        }
      })
      this.player.play()
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.init()
    })
  },

  beforeDestroy () {
    this.player && this.player.destroy()
  }

}
</script>

<style lang="scss">
.flv-player-wrapper {
  width: 100%;
  height: 100%;
  .dplayer-video {
    object-fit: fill;
  }
  .dplayer-volume, .dplayer-time, .dplayer-setting {
    display: none!important;
  }
}
</style>
